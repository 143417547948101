import React from 'react';

interface IProductDescriptionProps {
    productDescription: string;
}

export const ProductDescription: React.FC<IProductDescriptionProps> = ({
    productDescription,
}) => (
    <div>
        {productDescription}
    </div>
);
