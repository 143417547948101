import React from 'react';
import { ProductLink } from '../product-link';
import styles from './style.css';

interface IProductImageProps {
    productName: string;
    productUrl: string;
    image: string;
}

export const ProductImage: React.FC<IProductImageProps> = ({
    productName,
    productUrl,
    image,
}) => (
    <ProductLink productName={productName} productUrl={productUrl} className="product-image">
        <img src={image} alt={productName} className={styles.productImage} />
    </ProductLink>
);
