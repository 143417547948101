import React, { useState } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { myCustomProductsConfig } from 'ui/page/customer-pages/layouts/my-custom-products-layout-state';
import { MyCustomProductsLayout } from 'ui/page/customer-pages/layouts/my-custom-products-layout';
import { GET_MY_CUSTOM_PRODUCTS, IMyCustomProduct } from 'graphql/customer/custom-products';
import { CustomProductCard } from 'ui/component/custom-product/custom-product-card';
import Pagination from 'ui/component/PaginationV1/Pagination';
import styles from './style.css';

declare global {
    interface Window {
        isSessionInitialized?: boolean;
    }
}

window.isSessionInitialized = false;

const MyCustomProductsPage: React.FC = () => {
    const t = usePhraseTranslater();
    const config = useReactiveVar(myCustomProductsConfig);
    const initialPageSize = getSessionStorageData('myCustomProductsPageSize', true) as number ?? config?.default_page_limit ?? 12;
    const pageSizeOptions = config?.products_per_page_values ?? [12, 24, 36];
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(GET_MY_CUSTOM_PRODUCTS, {
        variables: {
            page: currentPage,
            pageSize,
        },
    });
    const productCount = data?.getMyProducts?.total_count ?? 0;
    const handlePageChange = async (page: number) => {
        setCurrentPage(page);
        await refetch({ page });
    };
    const updatePageSize = (size: number) => {
        setPageSize(size);
        setSessionStorageData('myCustomProductsPageSize', size);
    };
    return (
        <div data-page-type="custom-product-list">
            <div className={styles.myProductList}>
                <MyCustomProductsLayout showSidebar>
                    { !loading ? (
                        <>
                            <div className={styles.pageTitle}>
                                <h1 className={styles.heading}>{t('My Custom Products')}</h1>
                            </div>
                            <div className={styles.myProductGrid}>
                                {data?.getMyProducts.items.map((product: IMyCustomProduct) => (
                                    <CustomProductCard key={product.entity_id} product={product} />
                                ))}
                            </div>
                            { productCount === 0 && <div>{t('You have not saved any custom products yet.')}</div> }
                            <div className={styles.pagination}>
                                <div>
                                    {t('Items %1 to %2 of %3',
                                        Math.min((currentPage - 1) * pageSize + 1, productCount).toString(),
                                        Math.min(currentPage * pageSize, productCount).toString(),
                                        productCount.toString())}
                                </div>
                                <div className={styles.paginationButtons}>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(productCount / pageSize)}
                                        onPageChange={handlePageChange}
                                        scrollToElementId="my-products-grid"
                                    />
                                </div>
                                <select
                                    className={styles.pageSizeSelect}
                                    value={pageSize}
                                    onChange={e => updatePageSize(parseInt(e.target.value, 10))}
                                >
                                    {pageSizeOptions.map(option => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    ) : (
                        <div className={styles.loading}><StandardLoadingImage /></div>
                    )}
                    { (error || (!loading && !data?.getMyProducts)) && <div>{t('An error occurred. Please try again later.')}</div> }
                </MyCustomProductsLayout>
            </div>
        </div>
    );
};

export { MyCustomProductsPage as default };
