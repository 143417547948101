import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { myCustomProductsConfig } from 'ui/page/customer-pages/layouts/my-custom-products-layout-state';
import styles from './style.css';

interface IProductActionsProps {
    productId: number;
}

export const ProductActions: React.FC<IProductActionsProps> = ({
    productId,
}) => {
    const config = useReactiveVar(myCustomProductsConfig);
    return (
        <div className={styles.productActions}>
            {config?.enable_mockup_download && (
                <div className={styles.downloadLink}>
                    <a
                        href={`${process.env.MAGENTO_API}/myproducts/index/download/id/${productId}`}
                        target="_self"
                        rel="noopener noreferrer"
                    >
                        <i className="fa-solid fa-download" />
                    </a>
                </div>
            )}
            <div className={styles.deleteLink}>
                <a
                    href={`${process.env.MAGENTO_API}/myproducts/index/delete/id/${productId}`}
                    target="_self"
                    rel="noopener noreferrer"
                >
                    <i className="fa-solid fa-trash" />
                </a>
            </div>
        </div>
    );
};
