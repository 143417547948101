/* eslint-disable react/no-danger */
import React from 'react';
import { IEmbroideryData } from 'graphql/customer/custom-products';
import styles from './style.css';

interface IProductEmbroideryProps {
    embroideryData: IEmbroideryData;
}

export const ProductEmbroidery: React.FC<IProductEmbroideryProps> = ({
    embroideryData,
}) => (
    <div className={styles.productEmbroidery}>
        {embroideryData.emb_text && (
            <div className={styles.textEmbroidery}>
                <div className={styles.textEmbroideryIcon}>
                    <i className="fa-solid fa-font" />
                    <div
                        className={styles.textEmbroideryInfo}
                        dangerouslySetInnerHTML={{ __html: embroideryData.emb_text }}
                    />
                </div>
            </div>
        )}
        {embroideryData.emb_logo && (
            <div className={styles.logoEmbroidery}>
                <div className={styles.logoEmbroideryIcon}>
                    <i className="fa-solid fa-image" />
                    <div
                        className={styles.logoEmbroideryInfo}
                        dangerouslySetInnerHTML={{ __html: embroideryData.emb_logo }}
                    />
                </div>
            </div>
        )}
        {embroideryData.emb_flag && (
            <div className={styles.flagEmbroidery}>
                <div className={styles.flagEmbroideryIcon}>
                    <i className="fa-solid fa-flag" />
                    <div
                        className={styles.flagEmbroideryInfo}
                        dangerouslySetInnerHTML={{ __html: embroideryData.emb_flag }}
                    />
                </div>
            </div>
        )}
    </div>
);
