import React from 'react';

interface IProductLinkProps {
    productName: string;
    productUrl: string;
    className?: string;
}

export const ProductLink: React.FC<React.PropsWithChildren<IProductLinkProps>> = ({
    productName,
    className,
    productUrl,
    children,
}) => (
    <a
        href={productUrl}
        className={className}
        title={productName}
    >
        {children}
    </a>
);
