import React from 'react';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { ProductImage } from './product-image';
import { ProductActions } from './product-actions';
import { ProductName } from './product-name';
import { ProductDescription } from './product-description';
import { ProductEmbroidery } from './product-embroidery';
import styles from './style.css';

interface ICustomProductCard {
    product: IMyCustomProduct;
}

export const CustomProductCard: React.FC<ICustomProductCard> = ({ product }) => {
    const productUrl = `${process.env.MAGENTO_API}/myproducts/index/myproduct/id/${product.entity_id}`;
    return (
        <div className={styles.productCard} key={product.entity_id}>
            <div className={styles.productImage}>
                <ProductImage
                    image={product.embroidery_data?.emb_image[0].thumb}
                    productName={product.product_name}
                    productUrl={productUrl}
                />
            </div>
            <div className={styles.productInfo}>
                <ProductName productName={product.product_name} productUrl={productUrl} />
                <ProductDescription productDescription={product.product_description} />
                {
                    product.embroidery_data.image_delay_text && (
                        <div className={styles.imageDelayMessage}>
                            {product.embroidery_data.image_delay_text}
                        </div>
                    )
                }
                <ProductEmbroidery embroideryData={product.embroidery_data} />
                <ProductActions productId={product.entity_id} />
            </div>
        </div>
    );
};
