import React from 'react';
import { ProductLink } from '../product-link';

interface IProductNameProps {
    productName: string;
    productUrl: string;
}

export const ProductName: React.FC<IProductNameProps> = ({
    productName,
    productUrl,
}) => (
    <div>
        <ProductLink
            productName={productName}
            className="productName"
            productUrl={productUrl}
        >
            {productName}
        </ProductLink>
    </div>
);
